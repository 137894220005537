import * as React from "react"
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Menu from "../components/menu"
import "../css/main.scss"
import StandardHeadContent from "../components/standardHeadContent";
import Cookies from "universal-cookie";
import Axios from "axios";
import { Link } from "gatsby"

const GiftsPage = () => {
    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }

    useEffect(() => {
        Axios.get("/log.php?page=gifts_"+lang);
    },[])

    return (
        <main>
            <Helmet>
                <title>Nina & Knut - 11. juli 2022</title>
                <StandardHeadContent/>
            </Helmet>

            <Menu lang={lang} changeLang={changeLang}/>

            <div id="duogram"></div>

            {lang != "en" &&
                <>
                    <h1>Gaver</h1>

                    <p>
                        Vi synes det er en gave i seg selv at dere vil være med på vår bryllupsfeiring,
                        og det er absolutt ikke nødvendig med noen ekstra gave i tillegg.


                    </p>
                    <p>
                        Enkelte har likevel spurt om gaveønsker og vi har skrevet noen ord om dette på <Link to={"/konto"}>denne siden</Link>.
                    </p>
                </>
            }
            {lang == "en" &&
                <>
                    <h1>Gifts</h1>

                    <p>
                        The gift is celebrating our wedding day with friends and family, no extra gift necessary.
                    </p>

                    <p>
                        Some guests have despite this requested a wishlist and we have written some words regarding
                        this on <Link to={"/konto"}>this page</Link>.
                    </p>
                </>
            }

        </main>
    )
}

export default GiftsPage
